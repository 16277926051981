import React from 'react'
import { Container, Row } from 'reactstrap'

import PageTemplate from '../components/pageTemplate'
import CompanyOverview from "../components/company"

let Company = () => (
  <PageTemplate title="会社情報">
    <Container className="py-5">
      <Row>
        <CompanyOverview />
      </Row>
    </Container>
  </PageTemplate>
)

export default Company
